
import { Outlet, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {useEffect} from "react";

const Layout = () => {
    useEffect(() => {
        document.body.style = "background-color: lightgrey;";
    }, []);

    return (

        <div className="App">
            <nav>
                <Navbar expand="sm"  data-bs-theme="dark" style={{ backgroundColor: 'black' }}>
                    <Container>
                        <Navbar.Brand  as={Link} to="/" eventKey="/home" title="Cedar Wash Ranch">
                            <div className="company-name">Cedar Wash Ranch</div>
                            <div className="company-name-sub">Flatonia, Texas</div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Nav>
                                <Nav.Link as={Link} to="/page/kiko_goats">About Kiko Goats</Nav.Link>
                                    { /**<NavDropdown.Item as={Link} to="/page/chickens">Chickens</NavDropdown.Item> **/ }
                                    <Nav.Link as={Link} to="/page/for_sale">Animals for Sale</Nav.Link>

                                <Nav.Link as={Link} to="/page/contact" eventKey="/page/contact" title="Contact">Contact</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </nav>
            <div>
                <Outlet />
                <p>&nbsp;</p>
                <p>
                    <div>&copy; Copyright 2024. Cedar Wash Ranch. All rights reserved.</div>
                </p>
                <div className="footer">
                    <a href="https://www.facebook.com/cedarwashranch" target="_blank"><span className="facebook bi bi-facebook"></span></a>
                    &nbsp; &nbsp;
                    <a href="https://www.instagram.com/cedarwashranch/" target="_blank"><span className="instagram bi bi-instagram"></span></a>
                </div>
                <p>&nbsp;</p>

            </div>
        </div>


    )
};

export default Layout;

