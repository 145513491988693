import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import {useEffect} from "react";

const KikoGoats = () => {
    return <div className="content">
        <Container>
            <Row md={1}>
                <Col>
                    <h1>Kiko Goats</h1>

                    <h2>What are they?</h2>
                    <p>
                        Kiko goats are a large, hardy, breed descended from feral goats of New Zealand that thrive under
                        harsh conditions despite a complete absence of human attention and veterinary care.
                        In the 1980's, these zero-maintenance feral goats were selectively crossbred with Anglo Nubian,
                        British Toggenberg, and Saanen stock to increase size and impart characteristics desirable for dairy and meat production.
                        These efforts resulted in what is recognized today as the Kiko breed, an animal that can do it
                        all with considerably less management, verterinary attention, and processed feed input than
                        other breeds.
                    </p>

                    <h2>What are they good for?</h2>
                    <div className="indent">

                        <h3>Meat Production</h3>
                        <p>
                            Kiko goats get their name from the Māori word for "meat".  Being of the same general size as Boer goats, with a fraction of the maintenance, Kikos are
                            an attractive option for meat production.  Kiko bucks are desirable for breeding into existing meat goat herds
                            to improve natural parasite-resistance and hardiness with little to no reduction in growth rate or butchered weight.
                            The vast majority of the bucks we sell are purchased for the purpose of breeding into Boer and Spanish goat herds.
                        </p>

                        <h3>Dairy Production</h3>
                        <p>
                            While not widely regarded as a dairy breed, Kikos do have a fair amount of dairy goat (Nubian, Toggenburg, Saanen) blood in
                            them. The quality of their milk lends to healthy fast-growing kids and they produce milk in volume sufficient for feeding triplets.
                            Dairy production isn't our forte, but we do milk some of our Kikos for personal consumption.
                            These goats aren't difficult to milk and we routinely get a couple of quarts of milk per day
                            from a doe, with plenty left over for the kids.
                        </p>

                        <h3>Brush Control / Range Improvement</h3>
                        <p>
                            Kikos are descended from feral goats that lived entirely off of what they were able to
                            forage, so it's no surprise that they would be good at it.
                            Being large (i.e., hungry) goats with a propensity for browsing, they are great for
                            controlling brush.
                            Here in central Texas, we have a multitude of undesirable and invasive species of vegetation
                            that these goats are happy to keep in check, including Yaupon Holly, Huisache, Mesquite,
                            Cedar, Blackbrush, and Greenbriar.
                            They also like to strip all the bark they can get off of larger Cedars, eventually killing
                            them (no complaints here). They love to eat oak leaves and sprouts but they don't damage the
                            tree and leave a nice clean browse line just above head height.
                            In the absence of more desirable forage, these goats will eat grass but they don't love it
                            and won't eat it to the ground like some breeds will. In fact, they don't like to eat off
                            the ground at all, which is probably why they don't have much problem with worms or other
                            parasites.
                        </p>

                        <h3>Entertainment</h3>
                        <p>
                            Goats are nothing, if not entertaining.   They are active, social, and are happy to interact with humans... especially humans who bring treats.
                        </p>
                    </div>

                </Col>
            </Row>
        </Container>
    </div>;
};

export default KikoGoats;