import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import {useEffect, useState} from "react";

const ForSale = () => {
    const data = [


        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Doe.",
            "dob": "2020-05-01",
            "name": "Mocha",
            "weight": "???",
            "description": "Commercial Kiko",
            "price": "450",
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/latte-sm.jpg",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Doe",
            "dob": "2021-12-29",
            "name": "Latte",
            "weight": "???",
            "description": "Commercial Kiko",
            "price": "450",
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Doe",
            "dob": "2021-12-29",
            "name": "Cinnamon",
            "weight": "???",
            "description": "Commercial Kiko",
            "price": "450",
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Doe",
            "dob": "2023-03-20",
            "name": "Honey",
            "weight": "???",
            "description": "Commercial Kiko",
            "price": "450",
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Doe",
            "dob": "2022-12-05",
            "name": "Geyda",
            "weight": "???",
            "description": "Commercial Kiko",
            "price": "450",
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Doe",
            "dob": "2023-03-01",
            "name": "Unnamed", // Lagatha's Girl
            "weight": "???",
            "description": "Commercial Kiko",
            "price": "450",
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/ragnar-sm.jpg ",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Buck",
            "dob": "2021-12-27",
            "name": "Ragnar",
            "weight": "225",
            "description":"Commercial Kiko",
            "price": "350",
            "sold": false,
            "hide": false
        },
        {
            "thumbnail": "/bertram-sm.jpg",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Buck",
            "dob": "2022-12-06",
            "name": "Bertram",
            "weight": "160",
            "description": "Commercial Kiko",
            "price": "350",
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Buckling",
            "dob": "2023-07-21",
            "name": "Scuba",
            "weight": "???",
            "description": "Registerable (50%) Kiko",
            "price": "450",
            "sold": false,
            "hide": false
        },


        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Buckling",
            "dob": "2023-07-23",
            "name": "Peter",
            "weight": "???",
            "description": "Registerable (50%) Kiko",
            "price": "450",
            "sold": false,
            "hide": false
        },


        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Kiko",
            "sex": "Buckling",
            "dob": "2023-10-11",
            "name": "Bahloo",
            "weight": "???",
            "description": "Registerable (50%) Kiko",
            "price": 450,
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Crossbreed",
            "sex": "Buckling",
            "dob": "2023-07-07",
            "name": "Lucky",
            "weight": "???",
            "description": "Kiko / Nigerian Dwarf cross",
            "price": "250",
            "sold": false,
            "hide": false
        },


        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Crossbreed",
            "sex": "Buckling",
            "dob": "2024-01=-24",
            "name": "Rusty",
            "weight": "???",
            "description": "Kiko / Nigerian Dwarf cross",
            "price": "250",
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Crossbreed",
            "sex": "Buckling",
            "dob": "2024-01=-24",
            "name": "Dusty",
            "weight": "???",
            "description": "Kiko / Nigerian Dwarf cross",
            "price": "250",
            "sold": false,
            "hide": false
        },

        {
            "thumbnail": "/goat-silhouette-sm.png",
            "type": "Goat",
            "breed": "Crossbreed",
            "sex": "Buckling",
            "dob": "2024-06-01",
            "name": "Unnamed", // Latte's Boy
            "weight": "???",
            "description": "Kiko / Nigerian Dwarf cross",
            "price": "250",
            "sold": false,
            "hide": false
        },

    ];

    function dateDiff(d1) {
        const Difference_In_Time = new Date()-d1;

        // To calculate the no. of days between two dates
        const Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

        const years = Math.floor(Difference_In_Days / 365);
        const weeks = Math.round((Difference_In_Days-(years*365)) / 7);

        if (years>2) {
            return years+" yrs";
        } else if (years>0) {
            return "1 yr "+Math.floor(weeks/4.5)+" mos";
        } else if (weeks>25) {
            return Math.round(weeks/4.5)+" mos";
        } else {
            return weeks+" wks";
        }
    }
    return <div className="content">
        <Container>
            <Row md={1}>
                <Col>
                    <h1>Animals for Sale</h1>

                    <p>
                        We always have a selection of animals that are up for sale and we keep this list up to date.
                        If you see something on this page, and it's not marked as SOLD, it's still available.
                        Drop us a line if you would like to come by and get a first-hand look at any of these animals.
                    </p>
                    <p>&nbsp;</p>

                    <Table className="for-sale-table">
                        <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Type</th>
                            <th>Breed</th>
                            <th>Sex</th>
                            <th>Age</th>
                            <th>Name</th>
                            <th>Weight</th>
                            <th>Description</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>

                        {data.filter(x => x.hide != true).map(function(a, index){
                        return <tr className={a.sold?"sold":"available"}>
                            <td>
                                <Image src={a.thumbnail}></Image>
                                { (a.sold )?<div className="sold-overlay">SOLD</div>:<div></div>}
                            </td>
                            <td className="nowrap">{a.type}</td>
                            <td className="nowrap">{a.breed}</td>
                            <td className="nowrap">{a.sex}</td>
                            <td className="nowrap">{dateDiff(Date.parse(a.dob))}</td>
                            <td className="nowrap">{a.name}</td>
                            <td className="nowrap">{a.weight} lbs</td>
                            <td>{a.description}</td>
                            <td className="nowrap">${a.price}</td>
                        </tr>
                            {}})}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    </div>;
};

export default ForSale;