import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import {useEffect} from "react";

const Chickens = () => {
    return <div className="content">
        <Container>
            <Row md={1}>
                <Col>
                    <h1>Chickens</h1>

                    <h2>Farm Fresh Eggs</h2>

                    <h2>Fertilized Chicken Eggs</h2>
                    <p>
                        Assorted/Crossbred
                        Easter Eggers
                        Brown Leghorns

                    </p>

                </Col>
            </Row>
        </Container>
    </div>;
};

export default Chickens;