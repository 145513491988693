import React, {useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import * as formik from 'formik';
import * as yup from 'yup';
import { useState } from 'react';

const Contact = () => {

    useEffect(() => {
        document.title = "Iron & Fury Fitness - Let's Talk";
        document.description = 'Send us a message through our contact form.';
    }, []);


    const [formResponseType, setFormResponseType] = useState("none");
    const [formResponse, setFormResponse] = useState("");

    const formSubmitResponse  = formik;
    const { Formik } = formik;
    const schema = yup.object().shape({
        firstName: yup.string().min(2).required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        location: yup.string().required("Location is required"),
        phone: yup.string(),
        email: yup.string().email('Invalid email').required("E-mail address is required"),
        mailOptIn: yup.bool(),
        message: yup.string().min(20).required("Message is required")
    });



    const sendContactForm = (data) => {
        const formDataStr = JSON.stringify(data);
        console.log("FORM DATA: "+formDataStr);

        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    //const response = JSON.parse(this.responseText);
                    const resBody = JSON.parse(this.responseText);
                    const msg = resBody.message;
                    setFormResponseType("success");
                    setFormResponse(msg);

                } else {
                    setFormResponseType("danger");
                    setFormResponse("Appologies.  There was an error processing your message. Please send me an e-mail jason@ironandfury.com.");
                }
            } else {
                setFormResponseType("primary");
                setFormResponse("Processing...");
            }
        };
        xhttp.open("POST", "https://6w0cv228cd.execute-api.us-west-2.amazonaws.com/prod/contact", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.send(formDataStr);
    };


    return <div className="content">
        <Container>
            <Row>
                <Col>
                    <h1>Let's Talk!</h1>
                </Col>
            </Row>
        </Container>
        <Container className="box">
            <Row md={1}>
                <Col>
                    <h2>How can we help you?</h2>
                    <p>We'd love to hear from you! Use the form below to send us your contact info and a message and we will get back to you at our earliest convenience.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    { (formResponse!=="") ?
                        <Alert variant={formResponseType}>
                            {formResponse}
                        </Alert>
                        :
                        <Container>
                            <Formik
                                validationSchema={schema}
                                onSubmit={sendContactForm}
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    location: '',
                                    phone: '',
                                    email: '',
                                    mailOptIn: false,
                                    message: ''
                                }}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <Form id="contact-form" noValidate onSubmit={handleSubmit}>
                                        <Row xs={2} lg={4}>
                                            <Form.Group as={Col} className="mb-3" controlId="firstName" >
                                                <Form.Label className="required">First Name</Form.Label>
                                                <Form.Control type="text" placeholder="First Name"
                                                              value={values.firstName}
                                                              onChange={handleChange}
                                                              isValid={touched.firstName && !errors.firstName}
                                                              isInvalid={!!errors.firstName}/>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstName}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} className="mb-3" controlId="lastName" required>
                                                <Form.Label className="required">Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Last Name"
                                                              value={values.lastName}
                                                              onChange={handleChange}
                                                              isValid={touched.lastName && !errors.lastName}
                                                              isInvalid={!!errors.lastName}/>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row xs={1} sm={2}>
                                            <Form.Group as={Col} className="mb-3" controlId="email">
                                                <Form.Label className="required">E-Mail Address</Form.Label>
                                                <Form.Control type="email" placeholder="Enter e-mail"
                                                              value={values.email}
                                                              onChange={handleChange}
                                                              isValid={touched.email && !errors.email}
                                                              isInvalid={!!errors.email}/>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} className="mb-3" controlId="phone">
                                                <Form.Label>Phone Number</Form.Label>
                                                <Form.Control type="phone" placeholder="Enter phone number"
                                                              value={values.phone}
                                                              onChange={handleChange}
                                                              isValid={touched.phone && !errors.phone}/>
                                            </Form.Group>
                                        </Row>

                                        <Form.Group className="mb-3" controlId="location">
                                            <Form.Label className="required">Location</Form.Label>
                                            <Form.Control type="text" placeholder="Nearest city/town"
                                                          value={values.location}
                                                          onChange={handleChange}
                                                          isValid={touched.location && !errors.location}
                                                          isInvalid={!!errors.location}/>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="message">
                                            <Form.Label className="required">Message</Form.Label>
                                            <Form.Control as="textarea" placeholder="" rows={4}
                                                          value={values.message}
                                                          onChange={handleChange}
                                                          isValid={touched.message && !errors.message}
                                                          isInvalid={!!errors.message}/>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="mailOptIn">
                                            <Form.Check type="checkbox" label="Opt-in to receive e-mails about promotions and events."
                                                        value={values.mailOptIn}
                                                        onChange={handleChange}
                                                        isValid={touched.mailOptIn && !errors.mailOptIn}/>
                                        </Form.Group>


                                        <Button variant="primary" type="submit">
                                            Send It!
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Container>
                    }
                </Col>
            </Row>
        </Container>
    </div>;
};

export default Contact;