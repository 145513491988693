import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import {useEffect} from "react";


const About = () => {

    useEffect(() => {
        document.title = "Cedar Wash Ranch - About";
        document.description = 'I\'m Jason Patton, certified personal trainer and owner of Iron &amp; Fury Fitness.';
    }, []);

    return  <div className="content">

        <Container>
            <Row md={1}>
                <Col>
                    <h1>About Cedar Wash Ranch</h1>

                    <p>
                        Cedar Wash Ranch is off the beaten path in Northwestern Lavaca County, near Flatonia, Texas.

                        Set on 50 acres, we raise goats and chickens, and grow vegetables.

                        We're working to build a family legacy that we can passing along to our progeny.




                       </p>
                    <p>
                        We breed Kiko goats for meat, dairy, and range improvement.  Our breeding program selects for natural resistance to parasites and health issues first and foremost, with maternal instinct in does a close second.  We aren't breeding highfalutin show goats here, just healthy, happy, low maintenance stock that live up to the qualities that put the Kiko breed on the map.
                    </p>
                </Col>
            </Row>
        </Container>
    </div>;
};

export default About;