import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./Home"
import About from "./pages/About";
import KikoGoats from "./pages/KikoGoats";
import Chickens from "./pages/Chickens";
import ForSale from "./pages/ForSale";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import "./App.css"

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path="/page/" element={<Layout/>}>
                    <Route path="/page/about" element={<About/>}/>
                    <Route path="/page/kiko_goats" element={<KikoGoats/>}/>
                    <Route path="/page/chickens" element={<Chickens/>}/>
                    <Route path="/page/for_sale" element={<ForSale/>}/>
                    <Route path="/page/contact" element={<Contact/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
