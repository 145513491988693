// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
 import Image from 'react-bootstrap/Image';
// import Card from 'react-bootstrap/Card';
// import CardGroup from 'react-bootstrap/CardGroup';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import {useEffect} from "react";

const Home = () => {

    useEffect(() => {
        document.body.style = "background-color: black";
        document.title = "Cedar Wash Ranch";
        document.description = 'Kiko Goat Breeder in Flatonia, Texas';
    }, []);

    return <div className="home">
        <img
            className="home-image"
            src="cwr1.jpg"
            alt="Cedar Wash Ranch"
        />
        <div className="home-logo">
            <div className="home-company-name">Cedar Wash Ranch</div>
            <div className="home-company-name-sub">Flatonia, Texas</div>
        </div>

        <div className="home-links">
            <Link to="/page/kiko_goats">Kiko Goats</Link>
            <Link to="/page/for_sale">Animals for Sale</Link>
            <Link to="/page/contact">Contact Us</Link>
        </div>

        <div className="footer">
            <div>&copy; Copyright 2024. Cedar Wash Ranch. All rights reserved.</div>

            <a href="https://www.facebook.com/cedarwashranch" target="_blank"><span className="facebook bi bi-facebook"></span></a>
            &nbsp; &nbsp;
            <a href="https://www.instagram.com/cedarwashranch/" target="_blank"><span className="instagram bi bi-instagram"></span></a>

        <p>&nbsp;</p>
        </div>

        {/*<CardGroup>*/}
        {/*            <Card >*/}

        {/*                <Card.Img className="home-preview-image" variant="bottom" src="../bp1185231900.jpg" />*/}
        {/*                <Card.Body>*/}
        {/*                    <Card.Title>Blog: Start the day right</Card.Title>*/}
        {/*                    <Card.Subtitle className="mb-2 text-muted">Boost Your Morning Routine with These Effective Nutritional Supplements</Card.Subtitle>*/}
        {/*                    <Card.Text>*/}
        {/*                    A well-rounded morning routine sets the tone for a productive day ahead. Alongside a nutritious breakfast and energizing activities, incorporating certain nutritional supplements into your morning regimen can further enhance your physical performance and overall well-being. In this blog post, we will explore some key supplements that can help you start your day on the right foot.*/}
        {/*                    </Card.Text>*/}
        {/*                    <Card.Link href="#">Read the full article.</Card.Link>*/}
        {/*                </Card.Body>*/}
        {/*            </Card>*/}
        {/*            <Card>*/}
        {/*                <Card.Body>*/}
        {/*                    <Card.Title>July Challenge of the Month</Card.Title>*/}
        {/*                    <Card.Subtitle className="mb-2 text-muted">50 Burpees a Day</Card.Subtitle>*/}
        {/*                    <Card.Text>*/}
        {/*                        Loved by few and dreaded by most, burpees are a dynamic full-body exercise that will challenge you physically and mentally. The challenge here is to knock out 50 burpees a day for a full month, with a goal of being able to get them all done in a single set by they end of that period.  To begin with, try breaking them up into sets of 10 throughout the day.  Work towards getting them done in fewer, incrementally larger, sets every day until you can knock them out in a single set of 50 reps.  If you can already do that from the start, work on improving your time every day. Good luck!*/}
        {/*                    </Card.Text>*/}
        {/*                    <Card.Link href="#">How to do burpees</Card.Link>*/}
        {/*                </Card.Body>*/}
        {/*            </Card>*/}
        {/*            <Card>*/}
        {/*                <Card.Body>*/}
        {/*                    <Card.Title>Get Results</Card.Title>*/}
        {/*                    <Card.Subtitle className="mb-2 text-muted">50 Burpees a Day</Card.Subtitle>*/}
        {/*                    <Card.Text>*/}
        {/*                        Lorem ipsum*/}
        {/*                    </Card.Text>*/}
        {/*                    <Card.Link href="#">How to do burpees</Card.Link>*/}
        {/*                </Card.Body>*/}
        {/*            </Card>*/}

        {/*    </CardGroup>*/}

    </div>;
};

export default Home;